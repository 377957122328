.App {
  /* margin-top: 15px; */
  margin-top: 20px; 
  /* margin-bottom: 100px; */
  border:0px;
  /* color: white; */
}

.App .navbar-brand {
  color: white;
  /* font-weight: bold; */
}
.App .navbar {
  background-color: #252525;

  /* font-weight: bold; */
  font: #f0f0f0;
}
.App .NavItem{
  color: white;
  /* color: white; */
}