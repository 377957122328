.Home .lander {
    padding: 0px 0;
    text-align: center ;
  }
  
  .Home .lander h3{
    padding: 0px 0;
    text-align: left ;
  }
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 50px
  }
  .Home .lander h2 {
    /* font-family: "Open Sans", sans-serif; */
    text-align: left ;
    font-weight: 400;
  }
  .Home .lander h4 {
    /* font-family: "Open Sans", sans-serif; */
    text-align: left ;
    /* font-weight: 400; */
  }
  .Home .lander p {
    /* color: #999; */
    /* color: rgb(255, 255, 255); */
    
  }
  
  .Home .notes h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .Home .notes p {
    color: #666;
  }

  .Home .lander div {
    padding-top: 20px;
  }
  .Home .lander div a:first-child {
    margin-right: 20px;
  }
  #container {
    height: 100%; /*500px*/
    min-width: 620px; 
    max-width: 1600px; 
    margin: 0 auto; 
    flex: 1 1 auto;
}
.loading {
    margin-top: 10em;
    text-align: center;
    color: gray;
}
#play-controls {
  text-align: center;
  min-width: 310px;
  max-width: 800px;
  margin: 0 auto;
  padding: 5px 0 1em 0;
}
#play-controls * {
  display: inline-block;
  vertical-align: middle;
}
#play-pause-button {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid silver;
  border-radius: 3px;
  background: #f8f8f8;
}
#play-range {
  margin: 2.5%;
  width: 70%;
}
#play-output {
  font-family: Arial, Helvetica, sans-serif;
}