
.Visualisation  {
    padding: 80px 0;
    text-align: center ;
  }
  
.Visualisation h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
.Visualisation p {
    color: #999;
  }


#container {
    height: 100%; /*500px*/
    min-width: 620px; 
    max-width: 1600px; 
    margin: 0 auto; 
    flex: 1 1 auto;
}
.loading {
    margin-top: 10em;
    text-align: center;
    color: gray;
}
#play-controls {
  text-align: center;
  min-width: 310px;
  max-width: 800px;
  margin: 0 auto;
  padding: 5px 0 1em 0;
}
#play-controls * {
  display: inline-block;
  vertical-align: middle;
}
#play-pause-button {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid silver;
  border-radius: 3px;
  background: hsl(0, 0%, 97%);
}
#play-range {
  margin: 2.5%;
  width: 70%;
}
#play-output {
  font-family: Arial, Helvetica, sans-serif;
}